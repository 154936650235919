.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.dbn-testnet-banner {
  text-align:  center;
  background-color: #ffb819;
}

.dbn-top-level-unexpected-error {
  padding: 3rem;
  color: var(--bs-red);
}

.dbn-wrong-chain {
  text-align: center;
  background-color: #ff4319;
  color:  white;
  padding: 5px;
}
.dbn-wrong-chain p {
  margin-bottom: 0;
}

.dbn-image-result {
  border-radius: 8px;
  background-color: var(--dbn-lighter-background-color);
  transition: 200ms;
}

.dbn-image-result.darkmode {
  background-color: #524283;
  transition: 200ms;
}

.dbn-image-holder {
}

.dbn-image-holder.no-image-data {
  background-color: white;
}

.dbn-image-holder.crosshairs {
  cursor: crosshair;
}

.dbn-image-control-bar button {
  font-size: 0.75rem;
  padding-left: 6px;
  padding-right: 6px;
}

.dbn-image-control-bar {
  margin-bottom: 20px;
  background-color: #aaa5c5;
  padding: 6px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  transition: 200ms;
}

.dbn-image-control-bar.darkmode {
  background-color: #150f38;
  transition: 200ms;
}

.dbn-image-control-bar .zoom-indicator {
  font-weight: bold;
}

.dbn-image-control-bar-fixed-pill-content {
  display: inline-block;
}

.dbn-image-description {
  text-align: center;
}
.dbn-image-description h5 {
  margin-bottom: 0px;
}
.dbn-image-description.darkmode {
  color: white;
  transition: 200ms;
}

.dbn-image-render-status {
  font-size:  0.8rem;
  padding: 5px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: 20px;
  transition: 200ms;
}
.dbn-image-render-status.darkmode {
  background-color: #050216!important;
  color: white;
  transition: 200ms;
}

.dbn-image-render-status-message {
  font-weight: bold;
}

.dbn-image-render-status-stats {
  font-style: italic;
  font-size: 0.75rem;
}

.dbn-image-render-status-stats.examinable {
  cursor: zoom-in;
}
.dbn-image-render-status-stats.examinable:hover {
  text-decoration: underline;
}

.dbn-image-render-status-render-gas-stat.changing {
  display: inline-block;
  min-width: 13ex;
}

.dbn-image-render-status-action button {
  font-size: 0.8rem;
  padding: 3px;
  line-height: 0.8rem;
  background: none;
  border: none;
}

.dbn-image-render-status-action button:hover {
  background-color: rgba(0,0,0,.1);
}
.dbn-image-render-status-action.darkmode button:hover {
  background-color: rgba(255,255,255,.2);
}

.dbn-image-render-status-cancel button {
  color: var(--bs-red);
}

.dbn-image-render-status-cancel button:hover {
  color: var(--bs-red);
}

.dbn-image-render-status-cancel button:focus {
  color: var(--bs-red);
}

.dbn-image-render-status-rerun.darkmode button {
  color: white;
}

.code-input-codemirror-holder {
  border: 1px solid rgba(0,0,0,.5);
  font-size: 0.9rem;
  border-radius: 8px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.code-input-codemirror-crashed {
  padding: 2em;
  color: var(--bs-red);
  font-style: italic;
}

.code-error-underline {
  border-bottom: 1px dotted red;
}

.code-input-share-status {
  font-style: italic;
  font-size: 0.8rem;
}

.code-input-share-status.hidden {
  opacity: 0;
  transition: 300ms;
}

body {
  /*background-color: #fbfaff !important;*/
  background-color: #d6d2e7 !important;
}

:root {
  --dbn-lighter-background-color: #ece9f5;
  --dbn-number-highlight: #1b1bac;
}

.dbn-about-body {
  background-color:  var(--dbn-lighter-background-color);
}

.dbn-about-content {
  
}

.dbn-low-level-code {
  font-size: .8rem;
  background-color: white;
  padding: 3px;
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,.2);
  min-height: 50px;
}

.dbn-low-level-code.wordwrap {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.dbn-sample-code-and-image {
  margin-bottom: 1rem;
}
.dbn-readonly-code-wrapper {
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,.2);
  font-size: .9rem;
  font-family: var(--bs-font-monospace);
  text-align: left;
}

.dbn-readonly-code-wrapper .cm-editor {
  border-radius: 3px;
}

.dbn-readonly-code-wrapper .cm-gutters {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.dbn-about-get-started {
  background-color: #524283;
  border-radius: 8px;
}

.dbn-reference-inline-code {
  font-family: monospace;
  font-weight: bold;
  white-space: nowrap;
}

.dbn-reference-inline-code.number {
  font-weight: normal;
  color: var(--dbn-number-highlight);
}

.dbn-reference-canvas-example-label {
  font-family: monospace;
  color: var(--dbn-number-highlight);
  font-size: 0.8rem;
}

.dbn-reference-code-and-image {
  font-size: .9rem;
}

.dbn-reference-code-and-image .dbn-ui-editable-code-wrapper {
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,.2);
}

.dbn-reference-code-and-image .cm-editor {
  border-radius: 3px;
}

.dbn-reference-code-and-image .cm-gutters {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.dbn-reference-code-and-image-go-edit {
  font-style: italic;
  float: right;
  font-size: 0.8rem;
}

.dbn-reference-code-and-image-go-edit a {
  text-decoration:  none;
}

.dbn-reference-section {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom:  2px solid rgba(0,0,0, .3);
}

.dbn-reference-section p {
  margin-top: 1rem;
}

.dbn-reference-section h5 {
  margin-top: 2rem;
}

.dbn-reference-index {
  padding-right: 0px !important;
}

.dbn-reference-index-item {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.dbn-reference-index-item h5 {
  margin-bottom:  0px;
  cursor: pointer;
}
.dbn-reference-index-item h5:hover {
  text-decoration: underline;
}

.dbn-reference-index-item.active-section {
  background-color: var(--dbn-lighter-background-color);
}

.dbn-reference-content {
  background-color: var(--dbn-lighter-background-color);
  padding-bottom: 202px;
}

.dbn-ui-editable-code-wrapper {
  position: relative;
}

.dbn-ui-editable-code-wrapper.crashed {
  padding: 1rem;
  color: var(--bs-red);
  font-style: italic;
}


.dbn-ui-editable-tooltip-holder {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
  padding-top: 3px;
  background-color: #d9d9d9;
  border-radius: 3px;
  width: 121px;

  z-index: 9;
  position: absolute;
  border: 1px solid #c7c7c7;
  box-shadow: 1.5px 1.5px 3px rgba(0,0,0,.5);
}

.dbn-ui-editable-tooltip-holder.dbn-ui-editable-tooltip-holder-vertical {
  padding-left: 2px;
  padding-right: 3px;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 20px;
  height: 121px;
}


.dbn-ui-editable-tooltip-holder .rc-slider-rail {
  background-color: var(--dbn-number-highlight);
}

.dbn-ui-editable-tooltip-holder .rc-slider-mark {
  display: none;
}

.dbn-ui-editable-tooltip-holder.dbn-ui-editable-tooltip-holder-colorpicker .rc-slider-rail {
  background:  linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 100%);
}

.dbn-ui-editable-tooltip-holder .rc-slider-track {
  display: none;
}

.dbn-ui-editable-tooltip-holder .rc-slider-handle {
  border: none;
  background-color: var(--dbn-number-highlight);
  height: 12px;
  width: 12px;
  margin-top: -4px;
}

.dbn-ui-editable-tooltip-holder.dbn-ui-editable-tooltip-holder-vertical .rc-slider-vertical .rc-slider-handle {
  margin-left: -4px;
}

.render-logs {
  background-color: #525252;
  color: white;
  font-family: monospace;
  padding: 3px;
  font-size: .75rem;
  border-radius: 3px;
}

.render-logs p {
  margin: 0px;
}

.ethereum-connect-navbar {
  background-color: #525252;
  padding-left:  10px;
  border-top-left-radius: 5px;
  border-radius: 5px;
  font-size: .75rem;
  white-space: nowrap;
}

.ethereum-connect-navbar button {
  font-size:  0.75rem;
}

.dbnft-status-dot {
  display: inline-block;
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 0.4rem;
  position: relative;
  top: 0.2rem;
  background-color: #c1bdbd;
}

.dbnft-status-dot.error {
  background-color:  red;
}

.dbnft-status-dot.pending {
  background-color:  #ffbc00;
}

.dbnft-status-dot.ok {
  background-color:  green;
}

.ethereum-connect-navbar .dbnft-status-dot {
  margin-right: 5px;
}

.ethereum-connect-connect-message {
  display: inline-block;
  margin-right: 5px;
  font-style: italic;
}

.dbn-image-mint-controls {
  width: 303px;
  margin-top: 20px;
}

.dbn-show-code-modal .modal-content {
  background-color: #d6d2e7;
}

.dbn-show-code-modal .modal-header {
  border-bottom-color: var(--bs-gray-600);
}

.dbn-mint-modal .modal-content {
  background-color: #d6d2e7;
}

.dbn-mint-modal .modal-header {
  border-bottom-color: var(--bs-gray-600);
}

.dbn-mint-modal .modal-footer {
  border-top:  none;
}

.dbn-mint-modal {
  font-size: 0.9rem;
}

.dbn-mint-modal-description {
  font-weight: bold;
  text-align: center;
}

.dbn-mint-modal-text {
  text-align:  center;
  overflow:  scroll;
}

textarea.dbn-nft-mint-ticket-entry {
  font-size: 0.8rem;
  font-family: monospace;
}

p.dbn-nft-mint-ticket-error {
  color: var(--bs-red);
  margin-bottom: 0px;
}

.dbn-nft-minter-transaction-hash {
  font-style: italic;
  font-size: 0.7rem;
}

.dbn-mint-status-alert-fine-print {
  font-size:  0.7rem;
}

.dbn-token-metadata-table {
  text-align:  left;
  font-size: 0.8rem;
}

.dbn-nft-viewer {
  text-align: center;
  padding-bottom: 100px;
}

.dbn-nft-viewer .dbn-image-control-bar {
  text-align: left;
}

.dbn-nft-viewer .dbn-image-render-status {
  text-align: left;
}

.dbn-nft-viewer-opensea {
  font-size: 0.9rem;
  padding-bottom: 0.5rem;
}

.dbn-nft-viewer-source h5 {
  margin-bottom: 0px;
}

.dbn-nft-viewer-ipfs-url {
  font-size: 0.8rem;
  margin-bottom: 0px;
  margin-top: 0px;
  font-style: italic;
}

.dbn-nft-gallery {
}

.dbn-nft-gallery-items-holder {
  line-height: 0px;
  text-align: center;
}

.dbn-nft-gallery-item {
  height: 101px;
  width: 101px;
  border:  1px solid black;
  box-sizing:  border-box;
  display: inline-block;
  line-height: 101px;
  position: relative;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, .5);
}

.dbn-nft-gallery-item-link {
  height: 101px;
  width:  101px;
  z-index: 9;
  display: inline-block;
  position: absolute;
  top: -1px;
  left: -1px;
}

.dbn-nft-gallery-item.image-viewer-present {
  border-color: rgba(0,0,0,0);
}


.dbn-nft-gallery-item .dbn-image-holder {
  position: absolute;
  top: -1px;
  left: -1px;
}

@keyframes itemLoaderSpin {
    from {transform:rotate(0deg);}
    20% {transform:rotate(30deg);}
    80% {transform:rotate(345deg);}
    to {transform:rotate(360deg);}
}

.dbn-nft-gallery-item-status {
  position: absolute;
  height: 20px;
  width: 20px;
  left: 79px;
  bottom: 0px;
  font-size: 15px;
  line-height: 20px;
}

.dbn-nft-gallery-item-loader {
  color: #1f1f83;
  animation: itemLoaderSpin 1s infinite linear;
}

.dbn-nft-gallery-item-error {
  font-size: 12px;
  color:  var(--bs-red);
}

